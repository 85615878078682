<template>
  <div class="wm-antiplagiarism-copyrightService">
    <div class="wm-antiplagiarism-copyrightService-container">
      <div class="wm-antiplagiarism-copyrightService-container-sidebar">
        <CopyrightSideBar />
      </div>
      <div class="wm-antiplagiarism-copyrightService-container-right">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import CopyrightSideBar from "components/copyright-service/CopyrightSideBar";
export default {
  name: "CopyrightService",
  data() {
    return {};
  },
  methods: {},
  components: {
    CopyrightSideBar,
  },
  created() {
    this.$store.commit("handleChangeCopyrightServiceSideBar");
  },
};
</script>

<style lang="scss" scoped>
.wm-antiplagiarism-copyrightService {
  &-container {
    @include flex-between(flex-start, normal);
    border: 1px solid #eceff1;
    border-radius: 5px;
    &-sidebar {
      width: 149px;
      border-right: 1px solid #eceff1;
      ::v-deep .copyright-sidebar-item:last-child {
        width: 121px;
      }
    }
    &-right {
      flex: 1;
    }
  }
}
</style>
